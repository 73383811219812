import React, { useState, useEffect, useCallback } from "react";
import Autocomplete from "react-google-autocomplete";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import request from "services/request";
import { useLoading } from "contexts/LoadingContextManagement";
import {
  RESTAURANTS_TYPES_LIST,
  TIME_ZONES,
  DEFAULT_ERROR_MESSAGE,
} from "common/constants";
import { useOnDropAccepted } from 'common/utils.ts';

import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import { useUserData } from "contexts/AuthContextManagement";

function AddLabosModal(props) {
  const { t } = useTranslation();
  const { restaurantData } = props;
  const { isLabo } = useUserData();

  const intialState = {
    name: "",
    address: "",
    country: "",
    zip_code: "",
    siret_number: "",
    latitude: "",
    longitude: "",
    timezone: "",
    type: "",
    city: "",
    state: "",
    ...(isLabo && {is_labo: false})
  };
  const [state, setState] = useState(intialState);

  const googleTimezoneUrl =
    "https://maps.googleapis.com/maps/api/timezone/json";

  const [files, setFiles] = useState([]);
  const { setLoading, setError } = useLoading();

  const [fd, setFd] = useState({ allergens: [] });

  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted
  });

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  useEffect(() => {
    if (restaurantData != null) {
      setState(restaurantData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    var restaurant;
    if (restaurantData != null) {
      restaurant = props.updateRestaurant(state);
    } else {
      restaurant = props.saverestaurant(state);
    }

    if (acceptedFiles.length) {
      fileUpload(await restaurant);
    }
    props.reloadRestaurant();
  };

  const fileUpload = async (restaurant, create = true) => {
    if (!acceptedFiles.length) {
      setFd({ allergens: [] });
      setLoading(false);
      return;
    }
    try {
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);

      await request.patchFile(
        `/restaurants/upload-image/${restaurant.id}`,
        formData
      );

      setLoading(false);
      setFd({ allergens: [] });
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
    }
  };

  const handleTypeSelect = (val) => {
    setState({ ...state, type: val });
  };

  const handleTimeZoneSelect = (val) => {
    setState({ ...state, timezone: val });
  };

  const onPlaceSelect = (place) => {
    var filtered_array = place.address_components.filter(function (
      address_component
    ) {
      return address_component.types.includes("country");
    });
    //country
    var country = filtered_array.length ? filtered_array[0].long_name : "";

    //city
    let matches = place.address_components.filter((address_component) =>
      ["locality", "colloquial_area"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let city = "";
    if (!matches || !matches.length) {
      console.log("Could not resolve city name.");
    } else {
      city = matches[0].short_name;
    }

    let stateMatches = place.address_components.filter((address_component) =>
      ["administrative_area_level_1"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let countryState = "";
    if (!stateMatches || !stateMatches.length) {
      console.log("Could not resolve countryState name.");
    } else {
      countryState = stateMatches[0].short_name;
    }
    setState((prevState) => ({
      ...prevState,
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      country: country,
      city: city,
      state: countryState,
    }));

    getRestaurantTimezone(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  const getRestaurantTimezone = async (latitude, longitude) => {
    try {
      const url = `${googleTimezoneUrl}?location=${latitude}%2C${longitude}&timestamp=0&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY} `;
      const result = await fetch(`${url}`, {
        method: "GET",
      });
      if (result.status !== 200) {
        const response = await result.json();
        return;
      }
      const response = await result.json();
      setState((prevState) => ({
        ...prevState,
        timezone: response.timeZoneId,
      }));
    } catch (error) {}
    return "";
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-restaurant"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title
          className="add-restaurants-modal-title"
          id="contained-modal-title-vcenter"
        >
          {restaurantData ? t("EditLabo") : t("AddLabo")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-restaurants-modal-body">
        <Container>
          <Form className="ps-0 pe-0" onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Row>
                <span className="add-restaurants-input-title ps-0">
                  {t("RestaurantName")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  required
                  onChange={(event) =>
                    setState({ ...state, name: event.target.value })
                  }
                  defaultValue={restaurantData ? restaurantData.name : ""}
                />
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <span className="add-restaurants-input-title ps-0">
                  {t("Address")}
                </span>

                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  onPlaceSelected={(place) => {
                    onPlaceSelect(place);
                  }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  defaultValue={restaurantData ? restaurantData.address : ""}
                />
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <span className="add-restaurants-input-title ps-0">
                  {t("Timezone")}
                </span>
                <Col lg={{ span: 12 }}>
                  <Row>
                    <Dropdown
                      className="ps-0 pe-0"
                      onSelect={handleTimeZoneSelect}
                    >
                      <Dropdown.Toggle
                        variant="button"
                        className="dropdown-toggle btn-icon restaurant-dropdown "
                      >
                        {state.timezone !== "" ? state.timezone : t("Timezone")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="type-dropdown-menu">
                        {TIME_ZONES.sort().map((t, i) => (
                          <Dropdown.Item
                            className="type-dropdown-menu-item"
                            key={i}
                            eventKey={t}
                          >
                            {t}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("City")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      required
                      onChange={(event) =>
                        setState({ ...state, city: event.target.value })
                      }
                      value={state.city}
                      defaultValue={restaurantData ? restaurantData.city : ""}
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("State")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      required
                      onChange={(event) =>
                        setState({ ...state, state: event.target.value })
                      }
                      value={state.state}
                      defaultValue={restaurantData ? restaurantData.state : ""}
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Country")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      required
                      onChange={(event) =>
                        setState({ ...state, country: event.target.value })
                      }
                      value={state.country}
                      defaultValue={
                        restaurantData ? restaurantData.country : ""
                      }
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("ZIPCode")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      required
                      onChange={(event) =>
                        setState({ ...state, zip_code: event.target.value })
                      }
                      defaultValue={
                        restaurantData ? restaurantData.zip_code : ""
                      }
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
              <Col lg={{ span: 6 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Type")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0"
                          onSelect={handleTypeSelect}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            className="dropdown-toggle btn-icon restaurant-dropdown "
                          >
                            {state.type ? state.type : t("SelectType")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="type-dropdown-menu">
                            {RESTAURANTS_TYPES_LIST.map((t, i) => (
                              <Dropdown.Item
                                className="type-dropdown-menu-item"
                                key={i}
                                eventKey={t}
                              >
                                {t}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={{span: isLabo ? 3 : 5, offset: 1}}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("SIRETNumber")}
                    </span>

                    <Form.Control
                      type="text"
                      className=""
                      required
                      onChange={(event) =>
                        setState({ ...state, siret_number: event.target.value })
                      }
                      defaultValue={
                        restaurantData ? restaurantData.siret_number : ""
                      }
                    />
                  </Row>
                </Col>
                
               {isLabo && <Col lg={{ offset: 1, span: 1 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0 mb-3">
                      {t("Labo")}
                    </span>
                      <Form className="ps-0 cursor-pointer">
                      <Form.Check // prettier-ignore
                        onChange={(e) => setState({ ...state, is_labo: e.target.checked })}
                        checked={state.is_labo}
                        type="switch"
                        id="custom-switch"
                      />
                    </Form>
                  </Row>
                </Col>}
              </Row>
            </Form.Group>

            <Row className="mt-4 photo-container">
              <Col lg={12}>
                <span className="add-restaurants-input-title ps-0">
                  {t("Photo")}
                </span>
                <div className="">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>{t("Drag&Drop")}</p>
                  </div>
                  <aside>
                    <span className="add-restaurants-input-title m-0">
                      {t("AcceptedFiles")}
                    </span>
                    <div className="files-list">
                      <ul>
                        {files.map((file, i) => (
                          <li
                            key={i}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex">
                              <img src={JPEG_ICON} alt="..." />
                              <p className="ps-3">
                                <span>{file.path}</span>
                                <span className="size">
                                  {parseInt(file.size * 0.001)} Kb
                                </span>
                              </p>
                            </div>
                            <div>
                              <img
                                src={EYE_ICON}
                                alt="..."
                                className="eye me-3"
                              />
                              <img
                                src={CLOSE_ICON}
                                alt="..."
                                className="close"
                                onClick={onRemoveFile(file, i)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </aside>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                  {!restaurantData && fd.image_path && !files.length && (
                    <div style={thumb}>
                      <div style={thumbInner}>
                        <img src={fd.image_path} style={img} alt="..." />
                      </div>
                    </div>
                  )}
                  {restaurantData &&
                    restaurantData.image_path &&
                    !files.length && (
                      <div style={thumb}>
                        <div style={thumbInner}>
                          <img
                            src={restaurantData.image_path}
                            style={img}
                            alt="..."
                          />
                        </div>
                      </div>
                    )}
                </div>
              </Col>
            </Row>

            <Row>
              <Modal.Footer className="add-restaurants-modal-footer pe-0">
                <Button type="submit" className="add-restaurant-confirm-btn">
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AddLabosModal;
