import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";


import FullSoonLogo from "assets/images/fullsoon_logo_1.svg";


import "./index.scss"
import moment from "moment";
import { useUserData } from "contexts/AuthContextManagement";

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto", paddingLeft: "0"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input form-control bg-transparent">{caption}</span>
    </>
  );
}

function ConsolidateModal({
  onHide,
  show,
  formData,
  data: { selectedIngredient },
  selectedRows,
}) {
  const { t } = useTranslation();
  const { selectedRestaurant} = useUserData();

  const totalPrice = selectedRows.reduce((sum, row) => {
    const laboItemsTotal = row.labo_items.reduce(
      (itemSum, item) => itemSum + item.product_price * item.product_quantity,
      0
    );
    return sum + laboItemsTotal;
  }, 0)?.toFixed(2);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-pdf-modal"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
      </Modal.Header>

      <Modal.Body>
        <Container style={{ maxWidth: "90%" }}>
         <div className="modal-header">
              <h3>{t("Good delivery consolidation for headquarters")}</h3>
              <Button
                variant="primary">
                {t("Download")}
              </Button>
         </div>
         <Card className="modal-card-container">
            <div className="logo-image"><img src={FullSoonLogo} alt="logo" /></div>
            <div className="modal-sub-container">
              <div className="left-side" style={{ width: "100%" }}>
                <div className="date-container">
                  <div style={{ fontSize: "21px", fontWeight: "bold"}}>{t("Delivery note")} <span style={{ color: "#8232FF" }}>{selectedRestaurant?.name}</span></div>
                  <div style={{ fontSize: "21px", fontWeight: "bold"}}>{t("From")} <span style={{ color: "#8232FF" }}>{moment(formData?.start_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</span> {t("to")} <span style={{ color: "#8232FF" }}>{moment(formData?.end_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</span></div>
                </div>
                <Card className="labo-container-modal">
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Labo Details")}</div>
                  </Card.Header>
                  <Card.Body>
                        <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input">
                              {t("Name")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedRestaurant?.name} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Phone")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption="" />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Mail")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption="" />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Address")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedRestaurant?.address} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("SIRET Number")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedRestaurant?.siret_number} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Contact name")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption="" />
                          </Col>
                      </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Card className="delivery-container">
              <Card.Header className="delivery-header">
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Delivery details")}</div>
              </Card.Header>
              <Card.Body className="delivery-body">
                {selectedRows.map((item, index) => (
                  <>
                  <Row>
                  <Col lg={6} className="p-0">
                    <p className="consolidate-details-sub-header">{item.restaurant.name} - <span className="fullsoon-color">{item?.labo_items?.reduce((total, currentValue) => total + (currentValue?.product_price * currentValue?.product_quantity), 0)}€ {t("INCL. VAT")}</span></p>
                    <p className="consolidate-details-sub-header">{t("From")} - <span className="fullsoon-color">{moment(item?.createdAt, "YYYY-MM-DD").format("DD MMMM YYYY")}</span> {t("to")} <span className="fullsoon-color">{moment(item?.send_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</span></p>
                  </Col>
                  </Row>
                  <Row className="mt-4">
                  <Col lg={12}>
                      <div className="p-4 allergens-container">
                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table bg-transparent">
                                <thead>
                                  <tr>
                                    <td style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Reference")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "50%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Product")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Packagings")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Quantity")}
                                    </td>
                                    
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Unit Cost")}{<sub>{t(" HT")}</sub>}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Total")} <sub>{t("EXCL. VAT")}</sub>
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("VAT")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold", color: "#8232FF" }}
                                    >
                                      {t("Price")}{<sub>{t(" INCL. VAT")}</sub>}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                      {item?.labo_items.map((item) => (<tr
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={item.provider_reference_number} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        <Text style={{overflow: "hidden"}} caption={item.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={item.conditioning} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={item.product_quantity} />
                                      </td>
                                      {/* <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption="0" />
                                      </td> */}
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={item.unit_cost} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={(item.product_price * item.product_quantity)?.toFixed(2)} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption="" />
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      <Text caption={(item.unit_price * item.product_quantity)?.toFixed(2)} />
                                      </td>
                                    </tr>)) }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                  </Col>
                  </Row>
                  </>
                ))}
               
              </Card.Body>
              <Card.Footer style={{ height: "100px", width: "100%", backgroundColor: "#8232FF", display: "flex", justifyContent: "end", alignItems: "center", color: "white", paddingRight: "50px" }}>
                  <span>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}><sub>{t("Total")} <sub>INCL. VAT  </sub></sub><span style={{ fontSize: "32px", fontWeight: "bold" }}>{totalPrice?.split(".")[0]}</span><sub>,{totalPrice?.split(".")[1]}€</sub></span>
                  </span>
              </Card.Footer>
                
            </Card>
         </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ConsolidateModal;
