import React from "react";
import moment from "moment";

import LineChart from "components/lineChart";

import "../index.scss";

const dummyMealData = [
  { date: "2025-01-19", price: 2, meal: "Lasagna" },
  { date: "2025-01-20", price: 3, meal: "Lasagna" },
  { date: "2025-01-21", price: 6, meal: "Lasagna" },
  { date: "2025-01-22", price: 4, meal: "Lasagna" },
  { date: "2025-01-23", price: 10, meal: "Lasagna" },
];

// Transform the data
const lineChartData = dummyMealData.map((item) => ({
  x: new Date(item.date).getTime() / 1000,
  y: item.price,
  label: `${item.price}€ ${item.meal}`,
}));

function IngredientEvolution(props) {
  return (
    <div
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-restaurant"
    >
      <div className="provider-ingredients-chart">
        <LineChart
          data={lineChartData}
          dateTimeFormatter="DD MMM"
          extendedDate={new Date(moment().format("YYYY-MM-DD")).getTime() / 1000}
        />
      </div>
    </div>
  );
}

export default IngredientEvolution;
