import React from "react";
import Pdf from "react-to-pdf";

import {
  Modal,
  Row,
  Col,
  Container,
  Card,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import FullSoonLogo from "assets/images/fullsoon_logo_1.svg";


import "./index.scss"
import moment from "moment";

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto", paddingLeft: "0"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input form-control bg-transparent">{caption}</span>
    </>
  );
}
const options = {
  orientation: "portrait",
  unit: "px",
  format: [window.outerHeight * 1.2, window.outerWidth * 0.6],
};

const ref = React.createRef();
function OrderPdfModal({
  onHide,
  show,
  data: { selectedIngredient },
}) {
  const { t } = useTranslation();
  
  // const totalPrice = selectedRows.reduce((finalTotal, row) => finalTotal + (row?.labo_items?.reduce((total, item) => total + (item.product_quantity * item.unit_price)), 0), 0)?.toFixed(2) 
  const totalPrice = selectedIngredient?.labo_items?.reduce((total, item) => total + (item.product_quantity * item.product_price), 0)?.toFixed(2)

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-pdf-modal"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
      </Modal.Header>

      <Modal.Body>
        <Container style={{ maxWidth: "90%" }}>
         <div className="modal-header">
              <h3>{t("Delivery note from a lab to a restaurant")}</h3>
             
              <Pdf options={options} targetRef={ref} filename={`labo-order-${selectedIngredient?.restaurant?.name}.pdf`}>
              {({ toPdf }) =>  <Button 
                onClick={toPdf}
                variant="primary">
                {t("Download")}
              </Button>}
            </Pdf>
         </div>
         <Card  ref={ref} className="modal-card-container">
            <div className="logo-image"><img src={FullSoonLogo} alt="logo" /></div>
            <div className="modal-sub-container">
              <div className="left-side">
                <div className="date-container">
                  <div style={{ fontSize: "21px", fontWeight: "bold"}}>{t("Order date")} : <span style={{ color: "#8232FF" }}>{moment(selectedIngredient?.createdAt).format("DD/MM/YYYY")}</span></div>
                  <div style={{ fontSize: "21px", fontWeight: "bold"}}>{t("Delivery date")} : <span style={{ color: "#8232FF" }}>{moment(selectedIngredient?.send_date).format("DD/MM/YYYY")}</span></div>
                </div>
                <Card className="labo-container-modal">
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Labo Details")}</div>
                  </Card.Header>
                  <Card.Body>
                        <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input">
                              {t("Name")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.restaurant_detail?.name} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Phone")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.restaurant_detail?.phone_number} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Mail")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.restaurant_detail?.email} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Address")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.restaurant_detail?.address} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("SIRET Number")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.restaurant_detail?.siret_number} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Contact name")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.restaurant_detail?.contact_name} />
                          </Col>
                      </Row>
                  </Card.Body>
                </Card>
              </div>
              <div className="right-side">
                <Card className="temerature-container">
                  <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Delivery temerature")}</div>
                  <div style={{ fontSize: "27px", display: "flex", alignItems: "end", flexDirection: "column" }}>
                  <span style={{  fontWeight: "bold", lineHeight: "15px" }}>°C</span>
                  {selectedIngredient.delivery_temperature_celcius ? <span>{selectedIngredient.delivery_temperature_celcius}&nbsp;&nbsp;&nbsp;</span> : 
                    <span style={{ lineHeight: "20px"}}>---------</span>
                  }
                  
                  </div>
                </Card>
                <Card className="pos-container">
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Customer details")}</div>
                  </Card.Header>
                  <Card.Body>
                        <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input">
                              {t("First name / Last name")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient.restaurant?.restaurant_detail?.contact_name} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("SIRET Number")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient.restaurant?.restaurant_detail?.siret_number} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Mail")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient.restaurant?.restaurant_detail?.email} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Address")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient.restaurant?.restaurant_detail?.address} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Phone")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient.restaurant?.restaurant_detail?.phone_number} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Restaurant")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient.restaurant?.restaurant_detail?.name} />
                          </Col>
                      </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Card className="delivery-container">
              <Card.Header className="delivery-header">
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Delivery details")}</div>
              </Card.Header>
              <Card.Body className="delivery-body">
                <Row className="mt-4">
                  <Col lg={12}>
                      <div className="p-4 allergens-container">
                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table bg-transparent">
                                <thead>
                                  <tr>
                                    <td style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Reference")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "50%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Product")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Packagings")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Quantity")}
                                    </td>
                                   
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Unit Price")}{<sub>{t(" HT")}</sub>}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Total EXCL. VAT")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("VAT")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold", color: "#8232FF" }}
                                    >
                                      {t("Price ")}{<sub>{t(" INCL. VAT")}</sub>}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                     {selectedIngredient?.labo_items.map((item) => (<tr
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={item.provider_reference_number} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        <Text style={{overflow: "hidden"}} caption={item.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={item.conditioning} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={item.product_quantity} />
                                      </td>
                                      {/* <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption="0" />
                                      </td> */}
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={item.unit_price} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={(item.product_price * item.product_quantity)?.toFixed(2)} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption="0" />
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      <Text caption={(item.product_price * item.product_quantity)?.toFixed(2)} />
                                      </td>
                                    </tr>)) }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{ height: "100px", width: "100%", backgroundColor: "#8232FF", display: "flex", justifyContent: "end", alignItems: "center", color: "white", paddingRight: "50px" }}>
                  <span>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}><sub>{t("Total")} <sub>INCL. VAT  </sub></sub><span style={{ fontSize: "32px", fontWeight: "bold" }}>{totalPrice?.split(".")[0]}</span><sub>,{totalPrice?.split(".")[1]}€</sub></span>
                  </span>
              </Card.Footer>
                
            </Card>
         </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default OrderPdfModal;
