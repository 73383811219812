import { useState, useEffect, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
import axios from "axios";
import ReactPaginate from "react-paginate";

import request from "services/request";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";

import CustomTable from "components/customTable";
import QuantityContainer from "components/quantityBtnContainer";

import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { cloneDeep, getTimezoneFormatUtc } from "common/utils.ts";
import USERS_ICON from "assets/images/icon/users-icon.svg";

import "./index.scss";

function LeftSide({formData, clear}) {
  const tableRef = useRef(null);
  const { t } = useTranslation();
  const { setError } = useLoading();
  const { selectedRestaurantId, selectedRestaurant } = useUserData();

  const [currentItems, setCurrentItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [lossesData, setLossesData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [loss, setLoss] = useState(null)

  const tableColumns = [
    {
      dataField: "name",
      caption: t("Name"),
      className: "fw-bold dashboardHotelCode",
      style: { width: "250px", fontWeight: "bold", display: "flex" },
      headerStyle: { width: "250px" },
      type: "string"
    },
    {
      dataField: "channel",
      caption: t("Channel"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string"
    },
    {
      dataField: "cost_ht",
      caption: t("Cost (excl tax)"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string",
    },
    {
      caption: t("Losses"),
      className: "text-center",
      headerClassName: "text-center",
      style: { width: "100px", fontWeight: "bold" },
      headerStyle: { width: "100px" },
      type: "customRender",
      render: (col, it) => {
        return <QuantityContainer currentValue={it?.quantity ?? 1} setCurrentValue={(value) => {
          updateOrderItemQty(it, value)
        }} />
      }
    },
    {
      dataField: "category",
      caption: t("Category"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string"
    },
    {
      dataField: "total_ht",
      caption: t("Total (excl tax)"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string"
    }
  ];

  const updateOrderItemQty = async (meal, value) => {
    const payload = {
      stocks: [
        {
          meal_id: meal.id,
          stock: value,
          difference: value - meal.stock?.theoretical_stock || 0,
          restaurant_id: meal.restaurant_id,
        }
      ],
    };
    const resultQuery = await request.post(`/meal-stocks`, payload);
    let updatedFinishedData = lossesData?.map(item => item.id === meal.id ? { ...item, stock: { ...item.stock, stock: value } } : item)
    setLossesData(updatedFinishedData)
  }

  const getLossesMeals = async () => {
    try {
      const result = await axios.get(
        `https://lcrhw5yjng.execute-api.eu-west-1.amazonaws.com/paul-rfp/${formData?.pos?.id ? formData.pos.id : selectedRestaurantId}`,
        {
          headers: {
            'x-api-key': 'ZJCgK42PMW98FqyyAGdyz35z24HMqgAM835arcba',
            'Content-Type': 'application/json',
          },
          params: {
            start_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.start_date,
            end_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.end_date,
          },
        }
      );
      setLoss(result.data)
      // setLossesData(result.meal_stock);
    } catch (error) {
      if (error?.status !== 499) {
        setLossesData(false);
      }
    }
  };

  useEffect(async () => {
    await getLossesMeals();
  }, [JSON.stringify(formData)]);

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const newFilteredData = cloneDeep(currentItems);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, result.value);
      isExist.state = "update";
      setCurrentItems(newFilteredData);
    }
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...lossesData] : []));
  };

  const selectChange = (it) => ({ target: { checked } }) => {
    const newSelectedProducts = JSON.parse(JSON.stringify(selectedRows));
    if (checked) {
      setSelectedRows(() => [...newSelectedProducts, it]);
    } else {
      setSelectedRows(() => newSelectedProducts.filter((p) => p.id !== it.id));
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      const result = await getLossesMeals(event.selected + 1);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setOrderDetailsModalShow(true);
  };

  const onReportRow = (orderData) => {
    setSelectedOrder(orderData);
  };

  return (
    <div className="my-losses">
      <Row>
        <Col>
          <Card className="losses-analytics-card">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h2 style={{ fontSize: "19px", fontWeight: "bold" }}>{t("Loss amount")}</h2>
                <p className="text-container">{t("On average")}</p>
              </div>
              <div>
                <img src={USERS_ICON} alt="users-icon" />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div>
                <h1 style={{ margin: "0px", fontSize: "51px", fontWeight: "bold", color: "#873CFC" }}>
                  {loss?.data?.stats?.total_loss}€
                </h1>
              </div>
              <div>
                <h3 style={{ margin: "0px", fontSize: "21px", fontWeight: "bold", color: "#873CFC" }}>-9%</h3>
                <p style={{ margin: "0px", fontSize: "10px", color: "#94A3B8" }}>{t("On sales")}</p>
              </div>
            </div>
          </Card>
        </Col>
        <Col className="">
          <Card className="losses-analytics-card">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h2 style={{ fontSize: "19px", fontWeight: "bold" }}>{t("Top 3 FP losses")}</h2>
              </div>
              <div>
                <img src={USERS_ICON} alt="users-icon" />
              </div>
            </div>
            <div>
              {
                loss?.data.stats?.top_meals?.map((meal, index) => (
                  <p key={index} className="text-container">{meal.value}</p>
                ))
              }
            </div>
          </Card>
        </Col>
        <Col className="">
          <Card className="losses-analytics-card">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h2 style={{ fontSize: "19px", fontWeight: "bold" }}>{t("Top 3 MP losses")}</h2>
              </div>
              <div>
                <img src={USERS_ICON} alt="users-icon" />
              </div>
            </div>
            <div>
            {
                loss?.data.stats?.top_ingredients?.map((ingredient, index) => (
                  <p key={index} className="text-container">{ingredient.value}</p>
                ))
              }
            </div>
          </Card>
        </Col>
        <Col className="">
          <Card className="losses-analytics-card">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h2 style={{ fontSize: "19px", fontWeight: "bold" }}>{t("Reason")}</h2>
              </div>
              <div>
                <img src={USERS_ICON} alt="users-icon" />
              </div>
            </div>
            <div>
            {
                loss?.data.stats?.top_reasons?.map((reason, index) => (
                  <p key={index} className="text-container"><span style={{ color: "#9150F6", fontSize: "16px", fontWeight: "bold" }}>{reason.count}</span> {reason.value}</p>
                ))
              }
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3 table-container">
        <CustomTable
          columns={tableColumns}
          data={loss?.data?.items}
          ref={tableRef}
          tableName="labo-order"
          pageName="labo"
          selectChange={selectChange}
          selectedProducts={selectedRows.map((p) => p.id)}
          selectAllProducts={selectAllRows}
          onRowSelectChanged={onRowSelectChanged}
          onUpdateRow={onUpdateRow}
          onReportRow={onReportRow}
        />

        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      </Row>
    </div>
  );
}

export default LeftSide;