import { getRandomNumber } from "views/occupancy/data";

export const CUTTOFF_HEIGHT = 850;
export const MAX_COLLAPISBLE_SIDEBAR = 1440;
export const DEBOUNCE_DELAY = 500;
export const ITEMS_PER_PAGE = 25;
export const localStoragePrefix = "fullsoon_";
export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong please try again later";

export const RESTAURANTS_TYPES_LIST = [
  "Ethnic South Asian (Pakistan India Afghan)",
  "Ethinc Italian",
  "Ethnic Mediterranean",
  "Ethinc sub-Saharan",
  "Ethnic North African",
  "Ethnic South American",
  "Ethnic Mexican",
  "Ethnic Japanese",
  "Ethnic Chinese",
  "Ethnic Korean",
  "Ethnic - Vietnamese",
  "Ethnic Balkan",
  "Ethnic French",
  "Fast Food",
  "Fast Casual",
  "Casual Dinning",
  "Premium casual",
  "Family style",
  "Fine dining",
  "Brasserie and Bistro",
  "Buffet",
  "Cafeteria",
  "Coffee House",
  "Destination Restaurant",
  "Pub",
  "Night Club",
];

export const RESTAURANT_USER_TYPE_LIST = [
  "Owner",
  "Manager",
  "Chef",
  "Sous Chef",
  "Cook",
  "Kitchen Assistant",
  "Waiter/Waitress",
  "Host/Hostess",
  "Bartender",
  "Dishwasher",
  "Cashier",
  "Delivery Driver",
  "Busser",
  "Sommelier",
  "Pastry Chef"
];

export const ACCESS_PAGES = [
  { value: "forecast", label: "Forecast" },
  { value: "meals", label: "Meals" },
  { value: "occupancy", label: "Occupancy" },
  { value: "stock", label: "Stock" },
  { value: "labo", label: "Labo" },
  { value: "finance", label: "Finance" },
  { value: "market_view", label: "Market view" },
  { value: "donation", label: "Donation" },
  { value: "admin", label: "Admin" },
  { value: "settings", label: "Settings" }
];


export const CURRENCIES_LIST = [
  { name: "usd", code: "840", symbol: "$" },
  { name: "gbp", code: "826", symbol: "£" },
  { name: "eur", code: "978", symbol: "€" },
  { name: "cad", code: "124", symbol: "$" },
];

export const EXPIRY_OPTIONS = [
  { label: "Good", value: "good", id: 1 },
  { label: "Soon", value: "soon", id: 2 },
  { label: "Expired", value: "expired", id: 3 },
  { label: "Not Expired", value: "no_expiry", id: 4 },
];


export const ALLERGENS = [
  { id: "SelectAllergen", value: "", label: "Select Allergen" },
  { id: "dairy", value: "daily", label: "Dairy" },
  { id: "gluten", value: "gluten", label: "Gluten" },
  { id: "sesame", value: "sesame", label: "Sesame" },
  { id: "nuts", value: "nuts", label: "Nuts" },
  { id: "fish", value: "fish", label: "Fish" },
  { id: "soya", value: "soya", label: "Soya" },
  { id: "eggs", value: "eggs", label: "Eggs" },
  { id: "crustaceans", value: "crustaceans", label: "Crustaceans" },
  { id: "lupin", value: "lupin", label: "Lupin" },
  { id: "molluscs", value: "molluscs", label: "Molluscs" },
  { id: "mustard", value: "mustard", label: "Mustard" },
  { id: "sulphites", value: "sulphites", label: "Sulphites" },
]

// export const UNITS = [
//   { id: "mm", value: "mm", label: "millimeter (mm)" },
//   { id: "cm", value: "cm", label: "centimeter (cm)" },
//   { id: "ml", value: "ml", label: "milliliter (ml)" },
//   { id: "cl", value: "cl", label: "centiliter (cl)" },// new
//   { id: "dL", value: "dL", label: "deciliter (dL)" },
//   { id: "mg", value: "mg", label: "milligram (mg)" },
//   { id: "g", value: "g", label: "gram (g)" },
//   { id: "kg", value: "kg", label: "kilogram (kg)" },
//   { id: "unit", value: "unit", label: "Unit" },
//   { id: "m", value: "m", label: "meter (m)" },
//   { id: "l", value: "l", label: "litre (l)" },
//   { id: "unity", value: "unity", label: "unity" },
//   { id: "oz", value: "oz", label: "ounce (oz)" },
//   { id: "fl oz", value: "fl oz", label: "fluid ounce (fl oz)" },
//   { id: "gal", value: "gal", label: "gallon (gal)" },
//   { id: "gill", value: "gill", label: "gill (1/2 cup)" },
//   { id: "in", value: "in", label: "inch (in)" },
//   { id: "c", value: "c", label: "cup (c)" },
//   { id: "pt", value: "pt", label: "pint (pt)" },
//   { id: "lb", value: "lb", label: "pound (lb)" },
//   { id: "qt", value: "qt", label: "quart (qt)" },
//   { id: "tsp.", value: "tsp.", label: "teaspoon (tsp.)" },
//   { id: "tbsp.", value: "tbsp.", label: "tablespoon (tbsp.)" },
//   // { id: "PIECE", value: "item", label: "Item" },
//   { id: "item", value: "item", label: "Item" },
// ];

export const UNITS = [
  { id: "SelectUnit", value: "", label: "Select Unit" },
  { id: "g", value: "g", label: "gram (g)" },
  { id: "kg", value: "kg", label: "kilogram (kg)" },
  { id: "cl", value: "cl", label: "centiliter (cl)" },
  { id: "ml", value: "ml", label: "milliliter (ml)" },
  { id: "l", value: "l", label: "litre (l)" },
  { id: "item", value: "item", label: "item" },
  { id: "cm", value: "cm", label: "centimeter (cm)" },
  { id: "m", value: "m", label: "meter (m)" },
]

export const INGREDIENT_STORAGE_AREAS = [
  { id: "SelectStoringArea", value: "", label: "Select Storing Area" },
  { id: "fridge", value: "fridge", label: "Fridge" },
  { id: "freezer", value: "freezer", label: "Freezer" },
  { id: "pantry", value: "pantry", label: "Pantry" },
  { id: "dryStorage", value: "dryStorage", label: "Dry Storage" },
  { id: "spiceRack", value: "spiceRack", label: "Spice Rack" },
  { id: "coldStorage", value: "coldStorage", label: "Cold Storage" },
  { id: "shelving", value: "shelving", label: "Shelving" },
  { id: "refrigeratedStorage", value: "refrigeratedStorage", label: "Refrigerated Storage" },
  { id: "walkInCooler", value: "walkInCooler", label: "Walk-in Cooler" },
  { id: "bulkStorage", value: "bulkStorage", label: "Bulk Storage" },
  { id: "dryGoodsStorage", value: "dryGoodsStorage", label: "Dry Goods Storage" },
  { id: "other", value: "other", label: "Other" }
];

export const STORAGE_UNITS = [
  { id: "SelectUnit", value: "", label: "Select Unit" },
  { id: "conditioning", value: "conditioning", label: "Conditioning" },
  { id: "recipe", value: "recipe", label: "Recipe" },
];

export const EATIN_EATAWAY_UNITS = [
  { id: "NON", value: "NON", label: "NON" },
  { id: "OUI", value: "OUI", label: "OUI" }
];

export const INGREDIENT_CATEGORIES = [
  { id: "SelectCategory", value: "", label: "Select Category" },
  { id: "eggs", value: "eggs", label: "Eggs" },
  { id: "milks", value: "milks", label: "Milks" },
  { id: "milkProduct", value: "milkProduct", label: "Milk Product" },
  { id: "drinks", value: "drinks", label: "Drinks" },
  { id: "oilsAndButter", value: "oilsAndButter", label: "Oils & Butter" },
  { id: "fatsAndOils", value: "fatsAndOils", label: "Fats & Oils" },
  { id: "fruits", value: "fruits", label: "Fruits" },
  { id: "fruitsAndVegetables", value: "fruitsAndVegetables", label: "Fruits & Vegetables"},
  { id: "pastaAndRiceAndLegumes", value: "pastaAndRiceAndLegumes", label: "Pasta, Rice and Legumes"},
  { id: "sauces", value: "sauces", label: "Sauces"},
  { id: "pasta", value: "pasta", label: "Pasta"},
  { id: "breadsAndBakery", value: "breadsAndBakery", label: "Breads & Bakery"},
  { id: "riceAndPulses", value: "riceAndPulses", label: "Rice & Pulses"},
  { id: "cheese", value: "cheese", label: "Cheese"},
  { id: "packaging", value: "packaging", label: "Packaging"},
  { id: "cleaning", value: "cleaning", label: "Cleaning"},
  {
    id: "gnbProducts",
    value: "gnbProducts",
    label: "Grain, Nuts & Baking Products",
  },
  { id: "herbsAndSpices", value: "herbsAndSpices", label: "Herbs and Spices" },
  { id: "meatFish", value: "meatFish", label: "Meat Fish" },
  { id: "vegetables", value: "vegetables", label: "Vegetables" },
  { id: "pulses", value: "pulses", label: "Pasta, Rice and pulses" },
  { id: "sauce", value: "sauce", label: "sauce" },
  {
    id: "cheeseAndButter",
    value: "cheeseAndButter",
    label: "Cheese and Butter",
  },
  { id: "brasserie", value: "brasserie", label: "Brasserie" },
  { id: "consommableCHR", value: "consommableCHR", label: "Consommable CHR" },
  { id: "oph", value: "oph", label: "OPH" },
  { id: "dryGroceries", value: "dryGroceries", label: "Dry Groceries" },
  {
    id: "desktopFurnitures",
    value: "desktopFurnitures",
    label: "Desktop Furnitures",
  },
  { id: "caterer", value: "caterer", label: "Caterer" },
  { id: "frozen", value: "frozen", label: "Frozen" },
  { id: "others", value: "others", label: "Others" },
];

export const NEW_INGREDIENT_CATEGORIES = [
  { id: "SelectCategory", value: "", label: "Select Category" },
  { id: "frozen", value: "frozen", label: "Frozen" },
  { id: "meatFish", value: "meatFish", label: "Meat Fish" },
  { id: "fruitsAndVegetables", value: "fruitsAndVegetables", label: "Fruits & Vegetables"},
  { id: "otherFreshProducts", value: "otherFreshProducts", label: "Other Fresh Products" },
  { id: "dryGroceries", value: "dryGroceries", label: "Dry Groceries" },
  { id: "drinks", value: "drinks", label: "Drinks" },
  { id: "packaging", value: "packaging", label: "Packaging"},
  { id: "others", value: "others", label: "Others" },
];
  
export const PROVIDER_INGREDIENT_CATEGORIES = [
  { id: "eggs", value: "eggs", label: "Eggs" },
  { id: "milkProduct", value: "milkProduct", label: "Milk Product" },
  { id: "drinks", value: "drinks", label: "Drinks" },
  { id: "fatsAndOils", value: "fatsAndOils", label: "Fats & Oils" },
  { id: "fruits", value: "fruits", label: "Fruits" },
  {
    id: "gnbProducts",
    value: "gnbProducts",
    label: "Grain, Nuts & Baking Products",
  },
  { id: "herbsAndSpices", value: "herbsAndSpices", label: "Herbs and Spices" },
  { id: "meatAndFish", value: "meatAndFish", label: "Meat & Fish" },
  { id: "vegetables", value: "vegetables", label: "Vegetables" },
  { id: "pulses", value: "pulses", label: "Pasta, Rice and pulses" },
  { id: "sauce", value: "sauce", label: "sauce" },
  {
    id: "cheeseAndButter",
    value: "cheeseAndButter",
    label: "Cheese and Butter",
  },
  { id: "brasserie", value: "brasserie", label: "Brasserie" },
  { id: "consommableCHR", value: "consommableCHR", label: "Consommable CHR" },
  { id: "oph", value: "oph", label: "OPH" },
  { id: "dryGroceries", value: "dryGroceries", label: "Dry Groceries" },
  {
    id: "desktopFurnitures",
    value: "desktopFurnitures",
    label: "Desktop Furnitures",
  },
  { id: "caterer", value: "caterer", label: "Caterer" },
  { id: "frozen", value: "frozen", label: "Frozen" },
  { id: "others", value: "others", label: "Others" },
];

export const MEAL_CATEGORIES = [
  { id: "SelectCategory", value: "", label: "SelectCategory" },
  // { id: "appetizers", value: "appetizers", label: "Appetizers" },
  { id: "starters", value: "starters", label: "Starters" },
  { id: "mainDishes", value: "mainDishes", label: "Main dishes" },
  { id: "desserts", value: "desserts", label: "Desserts" },
  { id: "drinks", value: "drinks", label: "Drinks" },
  { id: "intermediate-recipe", value: "intermediate-recipe", label: "Intermediate Recipe" },
  { id: "option", value: "option", label: "Option" },
  { id: "side", value: "side", label: "Side" },
  { id: "sauce", value: "sauce", label: "Sauce" },
];

export const MEAL_CATEGORIES_DEMO = [
  { id: "starters", value: "starters", label: "Starters" },
  { id: "mainDishes", value: "mainDishes", label: "Main dishes" },
  { id: "desserts", value: "desserts", label: "Desserts" },
];

export const SERVINGS = [
  { id: 1, label: "1 person" },
  { id: 2, label: "2 persons" },
  { id: 3, label: "3 persons" },
  { id: 4, label: "4 persons" },
  { id: 5, label: "5 persons" },
  { id: 6, label: "6 persons" },
];

export const TIMES_CHECKBOXES = [
  {
    name: "breakfast",
    label: "Breakfast (00.00 - 11.00)",
    time: "00:00-10:59",
  },
  { 
    name: "lunch", 
    label: "Lunch (11.00 - 15.00)", 
    time: "11:00-14:59" 
  },
  {
    name: "afternoon",
    label: "Afternoon (15.00 - 18.00)",
    time: "15:00-17:59",
  },
  { 
    name: "dinner", 
    label: "Dinner (18.00 - 00:00)", 
    time: "18:00-23:59" 
  },
];

export const TIME_DURATIONS = {
  allDay: ["00:00", "23:59"],
  breakfast: ["00:00", "10:59"],
  lunch: ["11:00", "14:59"],
  afternoon: ["15:00", "17:59"],
  dinner: ["18:00", "23:59"],
};

export const SAMPLE_TOP_FLOPS = {
  TOP: [
    { meal: "Mozarella", sales: getRandomNumber(100, 1000), type: null },
    { meal: "Beers", sales: getRandomNumber(100, 1000), type: null },
    { meal: "Tiramisu", sales: getRandomNumber(100, 1000), type: null },
  ],
  FLOP: [
    { meal: "LATTE", sales: getRandomNumber(100, 1000), type: null },
    { meal: "Carrot cake", sales: getRandomNumber(100, 1000), type: null },
    { meal: "Pizza", sales: getRandomNumber(100, 1000), type: null },
  ],
};

export const EVENT_TYPES = [
  {id: "sports", label: "Sports", value: "sports" },
  {id: "cultural", label: "Cultural", value: "cultural" },
  {id: "internal", label: "Internal", value: "internal" },
  {id: "professional", label: "Professional", value: "professional" },
  {id: "exceptional_discount", label: "Exceptional Discount", value: "exceptional_discount" },
  {id: "closing", label: "Closing", value: "closing" },
  {id: "other", label: "Other", value: "other" },

];

export const TIME_ZONES = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const WEEKDAYS = [
  {id: 0,day: "Monday"},
  {id: 1,day: "Tuesday"},
  {id: 2,day: "Wednesday"},
  {id: 3,day: "Thursday"},
  {id: 4,day: "Friday"},
  {id: 5,day: "Saturday"},
  {id: 6,day: "Sunday"},
];

export const DUMMY_INGREDIENTS = [
  "Milk",
  "Cream",
  "Tomatoes",
  "Cheese",
  "Pasta",
  "Sugar",
  "Flour",
  "Butter",
  "Buttermilk",
  "Chili Sauce",
  "Egg",
  "Garlic",
  "Ketchup",
  "Onion",
  "Marshmallows",
  "Mayonnaise",
  "Rice",
  "Yeast",
];

export const LANGUAGES = [
  { name: "English", code: "en" },
  { name: "French", code: "fr" },
  { name: "Spanish", code: "es" },
];
