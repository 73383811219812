import { useOnDropAccepted } from "common/utils";
import { useLoading } from "contexts/LoadingContextManagement";
import { useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Autocomplete from "react-google-autocomplete";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "./index.scss";
import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import request from "services/request";
import { toast } from "react-toastify";
import { RESTAURANTS_TYPES_LIST, TIME_ZONES } from "common/constants";

const intialState = {
  name: null,
  address: null,
  timezone: null,
  city: null,
  state: null,
  country: null,
  zip_code: null,
  siret_number: null,
  type: null,
  is_labo: false
}

const colourStyles = {
  placeholder: (defaultStyles) => {
    return {
        ...defaultStyles,
        color: "#6353EA",
        opacity: "60%"
      }
  },
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#6353EA",
    }
  },
  option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    };
  },
  multiValueLabel: (base) => ({ ...base, color: "#6353EA", fontWeight: 600, fontSize: "15px" }),
  control: (styles) => ({
    ...styles,
    minHeight: 55, // Set the height of the input field
    height: "auto",
    backgroundColor: "#efedfd", // Set the background color
    borderRadius: 10, // Set the border radius
    padding: "5px"
  }),
};

function AddRestaurantForm({showFormHandler, restaurantData, stateHandler}) {
  const { t } = useTranslation();
  const [state, setState] = useState(intialState);
  const [files, setFiles] = useState([]);
  const { setLoading, setError } = useLoading();

  const [fd, setFd] = useState({ allergens: [] });
  let providerData = null;

  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const googleTimezoneUrl =
    "https://maps.googleapis.com/maps/api/timezone/json";

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted
  });

  const handleTimeZoneSelect = (val) => {
    setState({ ...state, timezone: val.label });
  };

  const onPlaceSelect = (place) => {
    var filtered_array = place.address_components.filter(function (
      address_component
    ) {
      return address_component.types.includes("country");
    });
    //country
    var country = filtered_array.length ? filtered_array[0].long_name : null;

    //city
    let matches = place.address_components.filter((address_component) =>
      ["locality", "colloquial_area"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );

    let zipCodeMatch = place.address_components.find((address_component) =>
      address_component.types.includes("postal_code")
    );

    let stateMatch = place.address_components.find((address_component) =>
      address_component.types.includes("administrative_area_level_1")
    );

    let city = null;
    if (!matches || !matches.length) {
    } else {
      city = matches[0].short_name;
    }

    setState((prevState) => ({
      ...prevState,
      address: place.formatted_address,
      country: country,
      city: city,
      state: stateMatch?.long_name ?? null,
      zip_code: zipCodeMatch?.long_name ?? null,
    }));

    getRestaurantTimezone(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  const getRestaurantTimezone = async (latitude, longitude) => {
    try {
      const url = `${googleTimezoneUrl}?location=${latitude}%2C${longitude}&timestamp=0&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY} `;
      const result = await fetch(`${url}`, {
        method: "GET",
      });
      if (result.status !== 200) {
        const response = await result.json();
        return;
      }
      const response = await result.json();
      setState((prevState) => ({
        ...prevState,
        timezone: response.timeZoneId,
      }));
    } catch (error) {}
    return "";
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const submitHandler = async() => {
    for (const key in state) {
      if (state.hasOwnProperty(key) && (state[key] === null || state[key] === '')) {
        toast.error(t("AllFieldsRequired"));
        return
      }
    }
    showFormHandler(false)
    const response = await request.post("/restaurants", {...state, latitude: 46, longitude: 12})
    const data = await response.clone().json();
    stateHandler(data)
  }

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  return (
    <Card style={{ width: "100%", padding: "40px", height: "max-content", margin: "20px auto" }}>
        <h1 style={{fontWeight: "bold"}}>{t("AddNewRestaurant")}</h1>
        <div className="pt-5">
          <Form>
            <Form.Group className="mb-5 flex justify-between">
              <Row>
                <Col lg={6} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("RestaurantName")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("RestaurantName")}
                    onChange={(event) =>
                      setState({ ...state, name: event.target.value })
                    }
                  />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("Address")}
                  </span>
                    <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    className="inputField"
                    onPlaceSelected={(place) => {
                      onPlaceSelect(place);
                    }}
                    options={{
                      types: ["geocode", "establishment"],
                    }}
                    defaultValue={providerData ? providerData.address : null}
                  />
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={6} className="p-0">
                   <span className="add-restaurants-input-title ps-0">
                    {t("TimeZone")}
                  </span>
                  <Select
                        styles={colourStyles}
                        onChange={handleTimeZoneSelect}
                        // onInputChange={handleInputChange}
                        components={makeAnimated()}
                        options={TIME_ZONES?.map((r) => ({
                          value: r,
                          label: r,
                        }))}
                        value={{value: state.timezone, label: state.timezone}}
                        className="selectInput"
                        closeMenuOnSelect={true}
                    />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("City")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("City")}
                    value={state.city}
                    required
                    onChange={(event) =>
                      setState({ ...state, city: event.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={6} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("State")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("State")}
                    value={state.state}
                    onChange={(event) =>
                      setState({ ...state, state: event.target.value })
                    }
                  />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("Country")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    required
                    placeholder={t("Country")}
                    value={state.country}
                    onChange={(event) =>
                      setState({ ...state, country: event.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={6} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("ZipCode")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("ZipCode")}
                    value={state.zip_code}
                    onChange={(event) =>
                      setState({ ...state, zip_code: event.target.value })
                    }
                  />
                </Col>
                <Col lg={{ span: 5, offset: 1 }} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("SiretNumber")}
                  </span>
                  <Form.Control
                    type="text"
                    className="inputField"
                    placeholder={t("SiretNumber")}
                    required
                    onChange={(event) =>
                      setState({ ...state, siret_number: event.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-5 flex justify-between">
                <Col lg={{span: 10}} className="p-0">
                  <span className="add-restaurants-input-title ps-0">
                    {t("Type")}
                  </span>
                  <Select
                        styles={colourStyles}
                        onChange={(event) =>
                          setState({ ...state, type: event.label })
                        }
                        // onInputChange={handleInputChange}
                        components={makeAnimated()}
                        options={RESTAURANTS_TYPES_LIST?.map((r) => ({
                          value: r,
                          label: r,
                        }))}
                        value={{value: state.type, label: state.type}}
                        className="selectInput"
                        closeMenuOnSelect={true}
                    />
                </Col>
                <Col lg={{span: 1, offset: 1}} className="p-0">
                  <Row>
                      <span className="add-restaurants-input-title ps-0 mb-3">
                        {t("Labo")}
                      </span>
                        <Form className="ps-0 cursor-pointer">
                        <Form.Check // prettier-ignore
                          onChange={(e) => setState({ ...state, is_labo: e.target.checked })}
                          checked={state.is_labo}
                          type="switch"
                          id="custom-switch"
                        />
                      </Form>
                    </Row>
                </Col>
              </Row>
              
            <Row className="mt-4 photo-container">
              <Col lg={12}>
                <span className="add-restaurants-input-title ps-0">
                  {t("Photo")}
                </span>
                <div className="">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>{t("Drag&Drop")}</p>
                  </div>
                  <aside>
                    <span className="add-restaurants-input-title m-0">
                      {t("AcceptedFiles")}
                    </span>
                    <div className="files-list">
                      <ul>
                        {files.map((file, i) => (
                          <li
                            key={i}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex">
                              <img src={JPEG_ICON} alt="..." />
                              <p className="ps-3">
                                <span>{file.path}</span>
                                <span className="size">
                                  {parseInt(file.size * 0.001)} Kb
                                </span>
                              </p>
                            </div>
                            <div>
                              <img
                                src={EYE_ICON}
                                alt="..."
                                className="eye me-3"
                              />
                              <img
                                src={CLOSE_ICON}
                                alt="..."
                                className="close"
                                onClick={onRemoveFile(file, i)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </aside>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                  {!restaurantData && fd.image_path && !files.length && (
                    <div style={thumb}>
                      <div style={thumbInner}>
                        <img src={fd.image_path} style={img} alt="..." />
                      </div>
                    </div>
                  )}
                  {restaurantData &&
                    restaurantData.image_path &&
                    !files.length && (
                      <div style={thumb}>
                        <div style={thumbInner}>
                          <img
                            src={restaurantData.image_path}
                            style={img}
                            alt="..."
                          />
                        </div>
                      </div>
                    )}
                </div>
              </Col>
            </Row>
            </Form.Group>
            <div className="d-flex justify-content-end"> 
              <Button onClick={submitHandler} variant="primary add-btn-container">{t("Submit")}</Button>
            </div>
          </Form>
        </div>
      </Card>
  );
}

export default AddRestaurantForm;
