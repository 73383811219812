import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";

import { DEFAULT_ERROR_MESSAGE, RESTAURANT_USER_TYPE_LIST, ACCESS_PAGES } from "common/constants";
import { DummyCompetitiveSetData } from "../utils";
import UploadedIcon from "assets/images/uploaded_meal.png";
import request from "services/request";

const getSelectStyles = () => ({
  placeholder: (styles) => ({ ...styles, color: "#6353EA", opacity: "60%" }),
  singleValue: (styles) => ({ ...styles, color: "#6353EA" }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: "white",
    color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
  }),
  multiValueLabel: (styles) => ({ ...styles, color: "#6353EA", fontWeight: 600, fontSize: "15px" }),
  control: (styles) => ({
    ...styles,
    minHeight: 55,
    backgroundColor: "#efedfd",
    borderRadius: 10,
    padding: "5px",
  }),
});

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  access_pages: [],
  type: "",
  restaurants: [],
};

function UserManagement() {
  const { t } = useTranslation();
  const [restaurants, setRestaurants] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const { setLoading, setError } = useLoading();
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } = useUserData();

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setRestaurants(DummyCompetitiveSetData);
      return;
    }
    getAllRestaurants();
  }, [selectedRestaurantId, hasRetaurants, isRestaurantLoaded]);

  const getAllRestaurants = useCallback(async () => {
    setLoading(true);
    try {
      const result = await request.get("restaurants/all");
      setRestaurants(result.restaurants.filter((r) => r.id !== selectedRestaurantId));
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  }, [selectedRestaurantId, setLoading, setError]);

  const handleSelectChange = (key) => (value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      setIsUploaded(true);
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        access_pages: [],
        type: "",
        restaurants: [],
      })
      setLoading(false);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const onHide = () => {
    setIsUploaded(false);
  };

  return (
    <div className="competitive-set">

      {isUploaded && (
        <Modal show={isUploaded} onHide={onHide} centered>
          <Modal.Body>
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("User uploaded !")}</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>{t("Your user has been uploaded successfully")}</label>
              </div>
              <div>
                <Button
                  className="add-restaurant-confirm-btn"
                  onClick={onHide}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Row>
        <Col lg={3}>
          <p className="title-text">{t("UserManagement")}</p>
          <p className="sub-title-text">{t("Manage your users here.")}</p>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("FirstName")}</label>
          <Form.Control
            type="text"
            className="inputField"
            placeholder={t("FirstName")}
            value={formData.first_name}
            onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
          />
        </Col>
        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("LastName")}</label>
          <Form.Control
            type="tel"
            className="inputField"
            placeholder={t("LastName")}
            value={formData.last_name}
            onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("Email")}</label>
          <Form.Control
            type="email"
            className="inputField"
            placeholder={t("Email")}
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </Col>
        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("Generate a password")}</label>
          <Form.Control
            type="password"
            className="inputField"
            placeholder={t("Generate a strong password")}
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("UserType")}</label>
          <Dropdown className="ps-0 pe-0 restaurant-input-field" onSelect={handleSelectChange("type")}>
            <Dropdown.Toggle variant="button" className="dropdown-toggle btn-icon inputField" style={{ width: "100%" }}>
              {formData.type || t("SelectUserType")}
            </Dropdown.Toggle>
            <Dropdown.Menu className="type-dropdown-menu">
              {RESTAURANT_USER_TYPE_LIST.map((type, index) => (
                <Dropdown.Item key={index} eventKey={type}>
                  {type}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("Access")}</label>
          <Select
            styles={getSelectStyles()}
            components={makeAnimated()}
            options={ACCESS_PAGES}
            isMulti
            value={formData.access_pages}
            onChange={handleSelectChange("access_pages")}
            className="selectInput"
            isSearchable
            placeholder={t("Select Access")}
            closeMenuOnSelect={false}
          />
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={6}>
          <label className="add-restaurants-input-title ps-0">{t("Restaurants")}</label>
          <Select
            styles={getSelectStyles()}
            components={makeAnimated()}
            options={restaurants.map((r) => ({ value: r.id, label: r.name }))}
            isMulti
            value={formData.restaurants}
            onChange={handleSelectChange("restaurants")}
            className="selectInput"
            isSearchable
            placeholder={t("SelectRestaurants")}
            closeMenuOnSelect={false}
          />
        </Col>
      </Row>

      <Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" className="add-restaurant-confirm-btn" onClick={handleSubmit}>
            {t("Confirm")}
          </Button>
        </div>
      </Row>
    </div>
  );
}

export default UserManagement;
