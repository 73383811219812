import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";

import SaveImage from "assets/images/saveImage.png";


function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control">{caption}</span>
    </>
  );
}

function OrderSaveModal({
  onHide,
  show,
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [] });
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-sent-labo"
      backdropClassName="add-ingredient-backdrop"
    >
      <div style={{ width: "90%", height: "447px", backgroundColor: "#873CFC", position: "absolute", top: "-300px", left: "50%", transform: "translate(-50%, 0)", zIndex: "-1", padding: "30px 100px", borderRadius: "35px"}}>
        <img width={{width: "100%"}} height={{height: "100%"}} src={SaveImage} alt="SaveImage" />
      </div>
     <div className="order-sent-modal-content">
        <h1 className="order-sent-modal-title">{t("MODIFICATIONS VALIDATED !")}</h1>
        <p className="order-sent-modal-text">
          {t("The quantities you changed have been successfully saved")}
        </p>
        <div className="order-sent-modal-button-container">
          <Button onClick={onHide} className="order-sent-modal-button">
            {t("Finish")}
          </Button>
        </div>
     </div>
    </Modal>
  );
}

export default OrderSaveModal;
