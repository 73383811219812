import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useFilterData } from "contexts/FilterContextManagment";

import Categories from "./categories/Categories";
import Providers from "./providers/Providers";
import Router from "../../routes/router";

import "./index.scss";

function Inventories() {
  const { t } = useTranslation();
  const location = useLocation();
  const { setShowCategoriesList } = useFilterData();

  const [isCategoryView, setIsCategoryView] = useState(true);

  useEffect(() => {
    const currentRoute = Router.find((r) => r.path === location.pathname);
    if (currentRoute?.path === "/stock") {
      setShowCategoriesList(true);
    }
  }, [location.pathname, setShowCategoriesList]);

  return (
    <>
      <div className="divider">
        <ul className="navbtns mb-0">
          <li className={`${isCategoryView ? "active" : ""}`}>
            <button
              className={`nav-link ${isCategoryView ? "active" : ""}`}
              onClick={() => setIsCategoryView(true)}
            >
              {t("Categories")}
            </button>
          </li>
          <li className={`${!isCategoryView ? "active" : ""}`}>
            <button
              className={`nav-link ${!isCategoryView ? "active" : ""}`}
              onClick={() => setIsCategoryView(false)}
            >
              {t("Providers")}
            </button>
          </li>
        </ul>
      </div>
      <div className="after-divider-container">
        {isCategoryView && <Categories />}
        {!isCategoryView && <Providers />}
      </div>
    </>
  );
}

export default Inventories;