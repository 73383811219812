import "./index.scss";

function Text({caption, style}) {
    let customStyle = {...style, border: 'none', width: "auto"}
    return (
      <>
        <span style={customStyle} className="add-restaurants-input-title form-control">{caption}</span>
      </>
    );
  }

const QuantityContainer = ({currentValue, setCurrentValue, style, index = 0}) => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        height: "36px",
        boxShadow: "1px 2px 6px -2px #8232FF",
        borderRadius: "41px",
        alignItems: "center",
        ...style
      }}
    >
      <span onClick={() => setCurrentValue(index, currentValue - 1)} className="labo-order-modal-quantity-action">-</span>
      <Text
        style={{
          color: "#873CFC",
          fontSize: "17px",
          fontWeight: "bold",
          marginTop: "5px !important",
          paddingTop: "0px",
          backgroundColor: "transparent",
        }}
        className="labo-order-modal-quantity"
        caption={currentValue}
      />
      <span onClick={() => setCurrentValue(index, currentValue + 1)} className="labo-order-modal-quantity-action">+</span>
    </div>
  );
};

export default QuantityContainer;
