import React from "react";
import { useSubMenuData } from "contexts/SidebarContextManagment";

import Order from "./order";
import ProductionPlanning from "./productionPlanning";
import SalesForecast from "./salesForecast";
import Analytics from "./analytics";

import "./index.scss";

function Labo() {
  const { selectedLaboSubMenu } = useSubMenuData();

  return (
    <div className="after-divider-container">
      {selectedLaboSubMenu === "Orders" && <Order />}
      {selectedLaboSubMenu === "Production Planning" && <ProductionPlanning />}
      {selectedLaboSubMenu === "Sales Forecast" && <SalesForecast />}
      {selectedLaboSubMenu === "Analytics" && <Analytics />}
    </div>
  );
}

export default Labo;