import LeftSide from "./leftSide";

import "./index.scss";

function Losses() {

  return (
    <>
      <LeftSide />
    </>
  );
}

export default Losses;
