import React, { useState, Fragment, useRef, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import { cloneDeep } from "common/utils";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import OrderStatusIcon from "assets/images/icon/order-status.svg";
import orderData from "../../../data/orders.json"

import "react-datepicker/dist/react-datepicker.css";
// import sample_ingredients_data from "../../data/stock_predictions.json";

const STATUS_CHECKBOXES = [
  { id: "1", name: "sent", label: "Sent" },
  { id: "2", name: "in-preparation", label: "In Preparation" },
  { id: "0", name: "cancelled", label: "Cancelled" },
];

const EXPIRY_CHECKBOXES = [
  { name: "good_to_eat", label: "Good to eat" },
  { name: "soon_to_expire", label: "Soon to be expired" },
  { name: "expired", label: "Expired" },
];

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */
const START_DATE = new Date(new Date().setDate(new Date().getDate()- 7));
const END_DATE = new Date(new Date().setDate(new Date().getDate() + 7));

function RightSide({ onApply: onSubmit, formData: fd, setClear }) {
  const { t } = useTranslation();
  const {
    isFilterShown,
    setFilterShown,
    selectedRestaurant,
    selectedRestaurantId,
    setResetStock,
    restaurants
  } = useUserData();

  const {
    filterStartEndDate,
    setFilterStartEndDate,
    selectedFilterProucts,
    setSelectedFilterProducts,
    isFilterActive,
    updateFilterButton
  } = useFilterData();

  const [selectedLaboOption, setSelectedLaboOption] = useState(selectedFilterProucts ?? []);
  const [selectedPOSOption, setSelectedPOSOption] = useState(selectedFilterProucts ?? []);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setformData] = useState({});
  const [startEndDate, setStartEndDate] = useState({
    start_date: filterStartEndDate && filterStartEndDate.start_date !== "" ? filterStartEndDate.start_date : START_DATE,
    end_date: filterStartEndDate && filterStartEndDate.end_date !== "" ? filterStartEndDate.end_date : END_DATE,
  });

  let dummyLaboDatasource = useMemo(
    () =>
      orderData.purchase_orders.map((m) => ({
        value: m.labo_name,
        label: m.labo_name,
        name: m.labo_name,
        item_id: m.id,
      })),
    []
  );

  let dummyPOSDatasource = useMemo(
    () =>
      orderData.purchase_orders.map((m) => ({
        value: m.restaurant,
        label: m.restaurant,
        name: m.restaurant,
        item_id: m.restaurant_id,
      })),
    []
  );

  /**
   * to be called whenever the user selects the date from date-window
   * @param {*} name field name
   * @returns
   */
  const onDateChange = (name) => (e) => {
    const maxEndDate = e < today ? new Date(today.getTime() - (24 * 60 * 60 * 1000)) : null;
    if (name == "start_date" && maxEndDate){
      setStartEndDate({ ...startEndDate, end_date: maxEndDate , [name]: e});
    } else {
      setStartEndDate({ ...startEndDate, [name]: e });
    }
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */
  const onApply = (isInitial = false) => {
    const payload = JSON.parse(
      JSON.stringify({ ...formData, ...startEndDate })
    );
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }
    payload.labos = selectedLaboOption.map((s) => s.id);
    payload.pos = selectedPOSOption.map((s) => s.id);
    // Extract STATUS_CHECKBOXES from formData
    payload.order_status = STATUS_CHECKBOXES
      .filter((item) => payload[item.name]) // Check if the key exists and is truthy in formData
      .map((item) => item.id); // Extract the names
    
    // Remove extracted keys from formData
    STATUS_CHECKBOXES.forEach((item) => {
      if (payload[item.name] !== undefined) {
        delete payload[item.name];
      }
    });
    
    if (typeof isInitial === "boolean" && isInitial !== false) {
      payload.labos = [];
      payload.pos = [];
      // payload.start_date = moment().format("YYYY-MM-DD");
      // payload.end_date = moment().format("YYYY-MM-DD");
      payload.order_status = []
      STATUS_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
      EXPIRY_CHECKBOXES.map((x) => x.name).forEach((t) => delete payload[t]);
    }

    onSubmit(payload);
  };

  /**
   * update various filter options
   * @param {*} param0
   */
  const onCheckboxChange = ({ target: { checked, name } }) => {
    setformData({ ...formData, [name]: checked });
  };

  const customCheckbox = ({ name, label, checked }) => {
    return (
      <Form.Group className="mb-3">
        <label className="checkbox">
          <input
            type="checkbox"
            onChange={onCheckboxChange}
            name={name}
            checked={checked}
          />
          <span className="me-2" />
          {label}
        </label>
      </Form.Group>
    );
  };

  const onClearDates = () => {
    setStartEndDate({
      start_date: START_DATE,
      end_date: END_DATE,
    });
  };

  const onClearAll = () => {
    onClearDates();
    setformData({});
    onClearProducts();
    onApply(true);
  };

  const handleClear = () =>{
    onClearAll();
    setClear(true);
  }

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearProducts = () => {
    setSelectedLaboOption([]);
    setSelectedPOSOption([]);
  };

  const onClearStatus = () => {
    const newFormData = cloneDeep(formData);
    STATUS_CHECKBOXES.map((x) => x.name).forEach((t) => delete newFormData[t]);
    setformData({ ...newFormData });
  };

  const onExpiryStatus = () => {
    const newFormData = cloneDeep(formData);
    EXPIRY_CHECKBOXES.map((x) => x.name).forEach((t) => delete newFormData[t]);
    setformData({ ...newFormData });
  };

  useEffect(() => {
    if (filterStartEndDate && filterStartEndDate.start_date !== "" && filterStartEndDate.end_date !== "") {
      setStartEndDate(filterStartEndDate);
    } else {
      setStartEndDate(startEndDate);
    }  
    setSelectedLaboOption(selectedFilterProucts);
    onApply();
  }, [])

  const onSelectOption = (dataField) => (v) => {
    if (dataField === "labos") {
      setSelectedLaboOption([...v]);
    }
    if (dataField === "pos") {
      setSelectedPOSOption([...v]);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const maxEndDate = startEndDate.start_date < today ? new Date(today.getTime() - (24 * 60 * 60 * 1000)) : null;

  return (
    isFilterShown && (
      <div className="rightcontent" style={{ width: "15%" }}>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col lg={4}>
              <h1 style={{ whiteSpace: "nowrap" }}>{t("Filters")}</h1>
              </Col>
              <Col lg={8}>
                <div
                  className="hide-filter"
                  onClick={() => setFilterShown(false)}
                >
                  {t("HideFilters")}
                </div>
              </Col>
            </Row>

            <div className="rightcontent-fltr">
              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Labo")}</label>
                  <button onClick={onClearProducts}>{t("Clear")}</button>
                </div>
                  <SelectAsyncPaginate
                    {...(!selectedRestaurant && {
                      datasource: dummyLaboDatasource,
                    })}
                    dataField="labos"
                    placeholder={t("Select")}
                    onChange={onSelectOption}
                    value={selectedLaboOption}
                    count={selectedLaboOption.length}
                    isMulti={true}
                    query="restaurants/all"
                    key={([]).join("_")}
                    // multiOptions={[]}
                  />
              </div>
              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("PointOfSale")}</label>
                  <button onClick={onClearProducts}>{t("Clear")}</button>
                </div>
                <SelectAsyncPaginate
                    {...(!selectedRestaurant && {
                      datasource: dummyPOSDatasource,
                    })}
                    dataField="pos"
                    placeholder={t("Select")}
                    onChange={onSelectOption}
                    value={selectedPOSOption}
                    count={selectedPOSOption.length}
                    isMulti={true}
                    query="labos/restaurants"
                    key={([]).join("_")}
                    // multiOptions={[]}
                  />
              </div>
              <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Date")}</label>
                  <button onClick={onClearDates}>{t("Clear")}</button>
                </div>
                <div className="d-flex datepicker-wrapper">
                  <span>{t("Start")}</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={new Date(startEndDate.start_date)}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("Start date")}
                      onChange={onDateChange("start_date")}
                      className="date-picker"
                      open={isStartDateOpen}
                      onClickOutside={setDateOpen("start_date")}
                      onClick={setDateOpen("start_date", true)}
                      // minDate={new Date()}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("start_date", !isStartDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
                <hr className="hr-separator" />
                <div className="d-flex datepicker-wrapper">
                  <span>{t("End")}&nbsp;&nbsp;</span>
                  <div className="datepicker-wrapper-img">
                    <DatePicker
                      selected={new Date(startEndDate.end_date)}
                      dateFormat="dd MMM yyyy"
                      placeholderText={t("End date")}
                      onChange={onDateChange("end_date")}
                      className="date-picker"
                      open={isEndDateOpen}
                      onClickOutside={setDateOpen("end_date")}
                      onClick={setDateOpen("end_date", true)}
                      // maxDate={maxEndDate}
                    />
                    <img
                      src={CalendarIcon}
                      onClick={setDateOpen("end_date", !isEndDateOpen)}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="mb-5">
               <Dropdown>
                 <Dropdown.Toggle style={{ width: "100%"}} variant="button" className="btn btn-white dropdown-toggle btn-icon">
                  <span style={{color: "#873CFC", fontWeight: "bold"}}>
                    <img src={OrderStatusIcon} alt="order-status" />
                    {t("Order Status")}
                  </span> 
                 </Dropdown.Toggle>
                 <Dropdown.Menu>
                   {STATUS_CHECKBOXES.map((check, i) => (
                     <Fragment key={i}>
                       {customCheckbox({
                         name: check.name,
                         label: t(check.label),
                         checked: formData[check.name] || false,
                       })}
                     </Fragment>
                   ))}
                 </Dropdown.Menu>
               </Dropdown>
              </div>

              {/* <div className="mb-5">
                <div className="fltr-heading">
                  <label>{t("Status")}</label>
                  <button onClick={onClearStatus}>{t("Clear")}</button>
                </div>
                {STATUS_CHECKBOXES.map((check, i) => (
                  <Fragment key={i}>
                    {customCheckbox({
                      name: check.name,
                      label: t(check.label),
                      checked: formData[check.name] || false,
                    })}
                  </Fragment>
                ))}
              </div> */}

              {/* <div>
                <div className="fltr-heading">
                  <label>{t("Expiry")}</label>
                  <button onClick={onExpiryStatus}>{t("Clear")}</button>
                </div>
                {EXPIRY_CHECKBOXES.map((check, i) => (
                  <Fragment key={i}>
                    {customCheckbox({
                      name: check.name,
                      label: t(check.label),
                      checked: formData[check.name] || false,
                    })}
                  </Fragment>
                ))}
              </div> */}
            </div>

            <div className="action-btns" style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column"
            }}>
              <div style={{
                display: "flex"
              }}>
                <button onClick={handleClear} className="close-btn me-3">
                  <img src={CloseIcon} alt="" />
                  {t("Clear")}
                </button>
                <button onClick={onApply} className="apply-btn">
                  {t("Apply")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default RightSide;
