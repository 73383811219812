import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import request from "services/request";

import {
  TIME_ZONES,
} from "common/constants";

import './index.scss';
import { useUserData } from "contexts/AuthContextManagement";

const googleTimezoneUrl = "https://maps.googleapis.com/maps/api/timezone/json";

function AddRestaurantDetailModal(props) {
  const { t } = useTranslation();
  const { selectedRestaurantId } = useUserData();
  const { restaurantData } = props;

  const intialState = {
    name: "",
    contact_name: "",
    phone_number: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    timezone: "",
    siret_number: "",
    restaurant_id: "",
  };
  const [state, setState] = useState(intialState);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);

 const getCurrentRestaurant = async () => {
  const result = await request.get(`restaurants/${selectedRestaurantId}`);
  if(result?.restaurant_detail) {
    setIsAlreadyAdded(true)
  }else {
    setIsAlreadyAdded(false)
  }
 }

  useEffect(() => {
    getCurrentRestaurant()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeZoneSelect = (val) => {
    setState({ ...state, timezone: val });
  };

  const onPlaceSelect = (place) => {
    var filtered_array = place.address_components.filter(function (
      address_component
    ) {
      return address_component.types.includes("country");
    });
    //country
    var country = filtered_array.length ? filtered_array[0].long_name : "";

    //city
    let matches = place.address_components.filter((address_component) =>
      ["locality", "colloquial_area", "administrative_area_level_2"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let city = "";
    if (!matches || !matches.length) {
      console.log("Could not resolve city name.");
    } else {
      city = matches[0].short_name;
    }

    let stateMatches = place.address_components.filter((address_component) =>
      ["administrative_area_level_1"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let countryState = "";
    if (!stateMatches || !stateMatches.length) {
      console.log("Could not resolve countryState name.");
    } else {
      countryState = stateMatches[0].short_name;
    }
    setState((prevState) => ({
      ...prevState,
      address: place.formatted_address,
      country: country,
      city: city,
      state: countryState,
    }));

    getRestaurantTimezone(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  const getRestaurantTimezone = async (latitude, longitude) => {
    try {
      const url = `${googleTimezoneUrl}?location=${latitude}%2C${longitude}&timestamp=0&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY} `;
      const result = await fetch(`${url}`, {
        method: "GET",
      });
      if (result.status !== 200) {
        const response = await result.json();
        return;
      }
      const response = await result.json();
      setState((prevState) => ({
        ...prevState,
        timezone: response.timeZoneId,
      }));
    } catch (error) {}
    return "";
  };

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    try {
      const result = await request.post(`/restaurants/details`, {...state, restaurant_id: selectedRestaurantId});
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        props.onHide()
      } 
    } catch (error) {
     console.log({error})
    }
    props.onHide()
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-restaurant"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title
          className="add-restaurants-modal-title"
          id="contained-modal-title-vcenter"
        >
          {restaurantData ? t("EditRestaurant") : t("AddRestaurantInvoice")}
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body className="add-restaurants-modal-body">
        <Container>
          <Form className="ps-0 pe-0" onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Row>
                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Name")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, name: event.target.value })
                  }
                  defaultValue={state.name}
                />
                </Col>
                <Col className="pe-1">
                <span className="add-restaurants-input-title ps-0">
                  {t("Contact name")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, contact_name: event.target.value })
                  }
                  defaultValue={state.contact_name}
                />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col className="ps-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Phone number")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, phone_number: event.target.value })
                  }
                  defaultValue={state.phone_number}
                />
                </Col>

                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Email")}
                </span>
                <Form.Control
                  type="email"
                  required
                  onChange={(event) =>
                    setState({ ...state, email: event.target.value })
                  }
                  defaultValue={state.email}
                />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-2">
              <Row style={{ borderRadius: "4px"}}>
                <span className="add-restaurants-input-title ps-0">
                  {t("Address")}
                </span>

                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  onPlaceSelected={(place) => {
                    onPlaceSelect(place);
                  }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  defaultValue={state.address}
                  className="restaurant-input-field"
                />
              </Row>
            </Form.Group>

            
            <Form.Group className="mb-2">
              <Row>
                <Col className="ps-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("City")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, city: event.target.value })
                  }
                  defaultValue={state.city}
                />
                </Col>

                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("State")}
                </span>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) =>
                    setState({ ...state, state: event.target.value })
                  }
                  defaultValue={state.state}
                />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col className="ps-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Country")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, country: event.target.value })
                  }
                  defaultValue={state.country}
                />
                </Col>

                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("SIRET number")}
                </span>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) =>
                    setState({ ...state, siret_number: event.target.value })
                  }
                  defaultValue={state.siret_number}
                />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
              <Col>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Timezone")}
                    </span>
                    <Col className="restaurant-input-field pe-3">
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0 restaurant-input-field"
                          style={{ borderRadius: "4px" }}
                          onSelect={handleTimeZoneSelect}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            style={{ borderRadius: "4px" }}
                            className="dropdown-toggle btn-icon restaurant-dropdown"
                          >
                            {state.timezone !== "" ? state.timezone : t("Timezone")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="type-dropdown-menu">
                            {TIME_ZONES.sort().map((t, i) => (
                              <Dropdown.Item
                                className="type-dropdown-menu-item"
                                key={i}
                                eventKey={t}
                              >
                                {t}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                </Col>

                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("ZIPCode")}
                </span>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) =>
                    setState({ ...state, zip_code: event.target.value })
                  }
                  defaultValue={state.zip_code}
                />
                </Col>
              </Row>
            </Form.Group>

            <Row>
              <Modal.Footer className="add-restaurants-modal-footer pe-0">
                <Button type="submit" disabled={isAlreadyAdded} className="add-restaurant-confirm-btn">
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AddRestaurantDetailModal;
