import { useState } from "react";
import LeftSide from "./leftSide";

import "./index.scss";

function FinishedProducts() {

  return (
    <>
      <LeftSide />
    </>
  );
}

export default FinishedProducts;
