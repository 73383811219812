import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import request from "services/request";
import DeleteModal from "views/commonViews/DeleteModal";
import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";

import AddRestaurantsModal from "./AddLabosModal";

import EditIcon from "assets/images/icon/EDIT.svg";
import DeleteIcon from "assets/images/icon/DELETE.svg";
import Addicon from "assets/images/icon/ADD_WHITE.svg";
import { myResturants } from "views/occupancy/data";
import AddLabosModal from "./AddLabosModal";
import AddLaboDetailModal from "./AddLaboDetailModal";

function MyLabos() {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const { isRestaurantLoaded, hasRetaurants, selectedRestaurantId } = useUserData();
  const [addLabosModalShow, setAddLabosModalShow] = useState(false);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [addLaboDetailModalShow,setAddLaboDetailModalShow] = useState(false)

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setRestaurants(myResturants);
      return;
    }
    getRestaurantsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRestaurantsData = async () => {
    try {
      setLoading(true);
      const result = await request.get("restaurants");
      setRestaurants(result.restaurants);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const saverestaurant = async (restaurantData) => {
    setLoading(true);
    try {
      const result = await request.post("/restaurants", restaurantData);

      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        setAddLabosModalShow(false);
        const newRestaurant = await result.json();
        setRestaurants([...restaurants, newRestaurant]);
        return data
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const deleteRestaurant = async (restaurantId) => {
    setLoading(true);
    try {
      const result = await request.delete(`/restaurants/${restaurantId}`, {});

      const data = await result.clone().json();
      // check for error response
      if (result.ok) {
        setRestaurants(
          restaurants.filter((restaurant) => restaurant.id !== restaurantId)
        );
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const updateRestaurant = async (restaurantData) => {
    var id = restaurantData.id || selectedRestaurantId;
    //didn't accept by the server
    delete restaurantData.id;
    delete restaurantData.image_path;
    delete restaurantData.location_id;

    setLoading(true);
    try {
      const result = await request.patch(`/restaurants/${id}`, restaurantData);
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        setAddLabosModalShow(false);
        setRestaurants(
          restaurants.map((r) => ({
            ...r,
            ...(r.id === data.id && data),
          }))
        );
        return data;
      } else {
        const errorMsg = (data && data.msg) || result.status;
        setError(`${errorMsg}`);
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const reloadRestaurant = () => {
    getRestaurantsData();
  };

  const item = (data, index) => (
    <Row key={index}>
      <Row className="pe-0">
        <Col lg={10}>
          <span className="restaurants-title">{data.name}</span>
        </Col>
        <Col className="icon-container" lg={2}>
          <img
            src={EditIcon}
            className="icon"
            alt=""
            onClick={() => {
              setSelectedRestaurant(data);
              setAddLabosModalShow(true);
            }}
          />
          <img
            src={DeleteIcon}
            className="icon"
            alt=""
            onClick={() => {
              setSelectedRestaurant(data);
              setDeleteModelShow(true);
            }}
          />
        </Col>
      </Row>
      <Row>
        <span className="restaurants-sub-title">{t("Address")}</span>
        <span className="restaurants-text">{data.address}</span>
        <span className="restaurants-sub-title">{t("SIRETNumber")}</span>
        <span className="restaurants-text">{data.siret_number}</span>
        {/* divider */}
        {index === restaurants.length - 1 ? (
          <></>
        ) : (
          <div className="divider"></div>
        )}
      </Row>
    </Row>
  );
  return (
    <div className="my-restaurants">
      {/* Email and name block */}

      <Row>
        <div className="d-flex row" style={{marginTop: "50px"}}>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("MyLabos")}</p>
          <p className="sub-title-text">{t("LaboList")}</p>
        </Col>
        <Col lg={{ span: 9}} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="primary add-btn-container"
              onClick={() => {
                setSelectedRestaurant(null);
                setAddLaboDetailModalShow(true);
              }}
            >
              <img src={Addicon} alt="" className="add-restaurant-btn-icon" />
              {t("AddLaboInvoice")}
          </Button>
       
          <Button
            variant="primary add-btn-container"
            onClick={() => {
              setSelectedRestaurant(null);
              setAddLabosModalShow(true);
            }}
          >
            <img src={Addicon} alt="" className="add-restaurant-btn-icon" />
            {t("AddLabo")}
          </Button>
       
        </Col>
        
        </div>
      </Row>
      {/* <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("MyLabos")}</p>
          <p className="sub-title-text">{t("LaboList")}</p>
        </Col>
        <Col lg={{ offset: 6, span: 3 }}>
          <Button
            variant="primary add-restaurant-btn"
            onClick={() => {
              setSelectedRestaurant(null);
              setAddLabosModalShow(true);
            }}
          >
            <img src={Addicon} alt="" className="add-restaurant-btn-icon" />
            {t("AddLabo")}
          </Button>
        </Col>
      </Row> */}
      <Row>
        <Col className="" lg={{ offset: 3, span: 9 }}>
          {restaurants.map((data, index) => item(data, index))}
        </Col>
      </Row>

      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModelShow(false)}
        onPositiveClicked={() => {
          setDeleteModelShow(false);
          deleteRestaurant(selectedRestaurant.id);
        }}
        modalData={{
          title: t("DeleteLabo"),
          description: t("DeleteLaboDescription"),
          positiveBtnTitle: t("DeleteLaboBtn"),
        }}
      />
      {addLabosModalShow ? (
        <AddLabosModal
          show={addLabosModalShow}
          onHide={() => setAddLabosModalShow(false)}
          saverestaurant={(data) => saverestaurant(data)}
          restaurantData={selectedRestaurant}
          updateRestaurant={(data) => updateRestaurant(data)}
          reloadRestaurant={() => reloadRestaurant()}
        />
      ) : null}
       {addLaboDetailModalShow ? (
        <AddLaboDetailModal
          show={addLaboDetailModalShow}
          onHide={() => setAddLaboDetailModalShow(false)}
          saverestaurant={(data) => saverestaurant(data)}
          restaurantData={selectedRestaurant}
          updateRestaurant={(data) => updateRestaurant(data)}
          reloadRestaurant={() => reloadRestaurant()}
        />
      ) : null}
    </div>
  );
}

export default MyLabos;
